import CacheService from "@/services/CacheService";

describe("CacheService", () => {
  afterEach(() => {
    jest.restoreAllMocks();
  });
  describe("isCacheable", () => {
    it("should return true if given requestUrl is a valid and not in black list string and method is 'get'", () => {
      // Given
      const requestUrl = "/user/v1/rentals/my-housfy/property/xxxxx-xxxx-xx-x";
      const method = "get";
      // When
      const result = CacheService.isCacheable(requestUrl, method);
      // Then
      expect(result).toBe(true);
    });
    it("should return false if given requestUrl is a valid and not in black list string and method is 'post'", () => {
      // Given
      const requestUrl = "/user/v1/rentals/my-housfy/property/xxxxx-xxxx-xx-x";
      const method = "post";
      // When
      const result = CacheService.isCacheable(requestUrl, method);
      // Then
      expect(result).toBe(false);
    });
    it("should return false if given requestUrl is a valid and not in black list string and method is 'put'", () => {
      // Given
      const requestUrl = "/user/v1/rentals/my-housfy/property/xxxxx-xxxx-xx-x";
      const method = "put";
      // When
      const result = CacheService.isCacheable(requestUrl, method);
      // Then
      expect(result).toBe(false);
    });
    it("should return false if given requestUrl is a valid and not in black list string and method is 'delete'", () => {
      // Given
      const requestUrl = "/user/v1/rentals/my-housfy/property/xxxxx-xxxx-xx-x";
      const method = "delete";
      // When
      const result = CacheService.isCacheable(requestUrl, method);
      // Then
      expect(result).toBe(false);
    });
    it("should return false if given requestUrl is an empty string and method is 'get'", () => {
      // Given
      const requestUrl = "";
      const method = "get";
      // When
      const result = CacheService.isCacheable(requestUrl, method);
      // Then
      expect(result).toBe(false);
    });
    it("should return false if given requestUrl is undefined and method is 'get'", () => {
      // Given
      const requestUrl = undefined;
      const method = "get";
      // When
      const result = CacheService.isCacheable(requestUrl, method);
      // Then
      expect(result).toBe(false);
    });
  });
  describe("checkUserToken", () => {
    it("should not to clean cache", () => {
      // Given
      const requestUrl = "/user/v1/rentals/my-housfy/property/xxxxx-xxxx-xx-x";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.checkUserToken("token1234");
      CacheService.addToCache(requestUrl, data);
      const expectedResult = data;
      // When
      CacheService.checkUserToken("token1234");
      // Then
      expect(CacheService.getFromCache(requestUrl)).toStrictEqual(
        expectedResult
      );
    });
    it("should clean cache", () => {
      // Given
      const requestUrl = "/user/v1/rentals/my-housfy/property/xxxxx-xxxx-xx-x";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.checkUserToken("token1234");
      CacheService.addToCache(requestUrl, data);
      // When
      CacheService.checkUserToken("token123");
      // Then
      expect(CacheService.getFromCache(requestUrl)).toBe(undefined);
    });
  });
  describe("getFromCache", () => {
    it("should return { test: 'This is a test object' } if requestUrl is given", () => {
      // Given
      const requestUrl = "testUrl";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.addToCache(requestUrl, data);
      const expectedResult = data;
      // When
      CacheService.getFromCache(requestUrl);
      // Then
      expect(CacheService.getFromCache(requestUrl)).toStrictEqual(
        expectedResult
      );
    });
    it("should return undefined if requestUrl is empty", () => {
      // Given
      const requestUrl = "";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.addToCache(requestUrl, data);
      // When
      CacheService.getFromCache(requestUrl);
      // Then
      expect(CacheService.getFromCache(requestUrl)).toBe(null);
    });
    it("should return undefined if requestUrl is undefined", () => {
      // Given
      const requestUrl = undefined;
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.addToCache(requestUrl, data);
      // When
      CacheService.getFromCache(requestUrl);
      // Then
      expect(CacheService.getFromCache(requestUrl)).toBe(null);
    });
  });
  describe("removeFromCache", () => {
    it("should remove a key from cache if requestUrl is valid", () => {
      // Given
      const requestUrl = "testUrl";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.addToCache(requestUrl, data);
      // When
      CacheService.removeFromCache(requestUrl);
      // Then
      expect(CacheService.getFromCache(requestUrl)).toBe(undefined);
    });
    it("should not remove a key from cache if requestUrl is not given", () => {
      // Given
      const requestUrlToAdd = "testUrl";
      const requestUrl = undefined;
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      const expectedResult = data;
      CacheService.addToCache(requestUrlToAdd, data);
      // When
      CacheService.removeFromCache(requestUrl);
      // Then
      expect(CacheService.getFromCache(requestUrlToAdd)).toStrictEqual(
        expectedResult
      );
    });
  });
  describe("addToCache", () => {
    it("should set a new property in the response object if requestUrl and data are given", () => {
      // Given
      const requestUrl = "testUrl";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      const expectedResult = data;
      // When
      CacheService.addToCache(requestUrl, data);
      // Then
      expect(CacheService.getFromCache(requestUrl)).toStrictEqual(
        expectedResult
      );
    });
    it("should NOT set a new property in the response object if requestUrl is an empty string", () => {
      // Given
      const requestUrl = "";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      // When
      const result = CacheService.addToCache(requestUrl, data);
      // Then
      expect(result).toBe(undefined);
    });
    it("should NOT set a new property in the response object if requestUrl is undefined", () => {
      // Given
      const requestUrl = undefined;
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      // When
      const result = CacheService.addToCache(requestUrl, data);
      // Then
      expect(result).toBe(undefined);
    });
    it("should NOT set a new property in the response object if requestUrl is a valid string but data is not given", () => {
      // Given
      const requestUrl = "testUrl";
      const data = undefined;
      // When
      const result = CacheService.addToCache(requestUrl, data);
      // Then
      expect(result).toBe(undefined);
    });
  });
  describe("clean", () => {
    it("should return undefined as in the response object there is all cleaned", () => {
      // Given
      const requestUrl = "testUrl";
      const data = new Promise(() => {
        return { test: "This is a test object" };
      });
      CacheService.addToCache(requestUrl, data);
      // When
      CacheService.clean();
      // Then
      expect(CacheService.getFromCache(requestUrl)).toBe(undefined);
    });
  });
});
